import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
// import { Container, Form, Card, Button } from "react-bootstrap";
// import Accordion from "react-bootstrap/Accordion";
import {
  Form,
  Card,
  FormCheck,
  Button,
} from "react-bootstrap";
// import "./calculateForm.css";

function SelectBhk(props) {
  const { setSelectedBhk, handleNext } = props; // Destructure prop
  const [selectedRadio, setSelectedRadio] = useState("");
  const handleRadioChange = (bhkValue) => {
    setSelectedRadio(bhkValue); // Update state on radio change
    setSelectedBhk(bhkValue); // Pass selected value to parent component
  };
  return (
    <div>
      <h2 className="mb-3">Select your BHK type</h2>

      <Form>
        {/* 1 BHK Card */}
        <Card
          className="mb-3 p-3"
          style={{ paddingLeft: "2rem" }}
          onClick={() => handleRadioChange("1 BHK")}
        >
          <div key={`default-radio`} className="mb-0 text-start">
            <FormCheck
              type="radio"
              id={`default-radio`}
              label={`1 BHK`}
              name="radioGroup"
              checked={selectedRadio === "1 BHK"} // Set checked based on state
            />
          </div>
        </Card>
        <Card
          className="mb-3 p-3"
          style={{ paddingLeft: "2rem" }}
          onClick={() => handleRadioChange("2 BHK")}
        >
          <div key={`default-radio`} className="mb-0 text-start">
            <FormCheck
              type="radio"
              id={`default-radio`}
              label={`2 BHK`}
              name="radioGroup"
              checked={selectedRadio === "2 BHK"} // Set checked based on state
            />
          </div>
        </Card>
        <Card
          className="mb-3 p-3"
          style={{ paddingLeft: "2rem" }}
          onClick={() => handleRadioChange("3 BHK")}
        >
          <div key={`default-radio`} className="mb-0 text-start">
            <FormCheck
              type="radio"
              id={`default-radio`}
              label={`3 BHK`}
              name="radioGroup"
              checked={selectedRadio === "3 BHK"} // Set checked based on state
            />
          </div>
        </Card>
        <Card
          className="mb-3 p-3"
          style={{ paddingLeft: "2rem" }}
          onClick={() => handleRadioChange("4 BHK")}
        >
          <div key={`default-radio`} className="mb-0 text-start">
            <FormCheck
              type="radio"
              id={`default-radio`}
              label={`4 BHK`}
              name="radioGroup"
              checked={selectedRadio === "4 BHK"} // Set checked based on state
            />
          </div>
        </Card>

        {/* Accordion for 2, 3, and 4 BHK */}
        {/* <Accordion>
          <AccordionItem eventKey="0" className="border-0">
            <AccordionHeader>
              <FormCheck
                type="radio"
                id={`default-radio-2`}
                label={`2 BHK`}
                name="radioGroup"
                checked={selectedRadio === "2 BHK"} // Set checked based on state
                onChange={() => handleRadioChange("2 BHK")} // Handle change on click
              />
            </AccordionHeader>
            <AccordionBody>2 BHK</AccordionBody>
          </AccordionItem>

          <AccordionItem eventKey="1" className="border-0">
            <AccordionHeader>
              <FormCheck
                type="radio"
                id={`default-radio-3`}
                label={`3 BHK`}
                name="radioGroup"
                checked={selectedRadio === "3 BHK"} // Set checked based on state
                onChange={() => handleRadioChange("3 BHK")} // Handle change on click
              />
            </AccordionHeader>
            <AccordionBody>3 BHK</AccordionBody>
          </AccordionItem>

          <AccordionItem eventKey="2" className="border-0">
            <AccordionHeader>
              <FormCheck
                type="radio"
                id={`default-radio-4`}
                label={`4 BHK`}
                name="radioGroup"
                checked={selectedRadio === "4 BHK"} // Set checked based on state
                onChange={() => handleRadioChange("4 BHK")} // Handle change on click
              />
            </AccordionHeader>
            <AccordionBody>4 BHK</AccordionBody>
          </AccordionItem>
        </Accordion> */}

        {/* 5+ BHK Card */}
        <Card
          className="mb-3 p-3"
          style={{ paddingLeft: "2rem" }}
          onClick={() => handleRadioChange("5+ BHK")}
        >
          <div key={`default-radio`} className="mb-0 text-start">
            <FormCheck
              type="radio"
              id={`default-radio-5`}
              label={`5 BHK+`}
              name="radioGroup"
              checked={selectedRadio === "5+ BHK"} // Set checked based on state
            />
          </div>
        </Card>
      </Form>

      <div className="text-right">
        <Button className="rounded-pill" variant="primary" onClick={handleNext}>
          Continue
        </Button>
      </div>
    </div>
  );
}

export default SelectBhk;
