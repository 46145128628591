import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useAuth } from "../../../AuthProvider";
import logo from "../../../Assets/img/logo4.png";
import "./header.css"; // Optional style file
import eventBus from "../eventBus";

export default function Header() {
  const { isLoggedIn } = useAuth();
  const [titleVal, setTitleVal] = useState("SignUp");
  const [phoneNumber, setPhoneNumber] = useState("");

  useEffect(() => {
    const storedValues = JSON.parse(sessionStorage.getItem("loginValues"));

    if (storedValues) {
      setTitleVal(storedValues.username);
      setPhoneNumber(storedValues.phoneNumber);
    }
    const handleSessionStorageUpdate = () => {
      const storedValues = JSON.parse(sessionStorage.getItem("loginValues"));
      setTitleVal(storedValues.username);
    };

    eventBus.subscribe("sessionStorageUpdated", handleSessionStorageUpdate);

    return () => {
      eventBus.unsubscribe("sessionStorageUpdated", handleSessionStorageUpdate);
    };
  }, []);

  const tooltip = <Tooltip id="tooltip">{phoneNumber}</Tooltip>;

  return (
    <Navbar expand="lg" className="bg-body-tertiary fixed-top">
      <Container fluid>
        <Navbar.Brand href="#home">
          <img
            src={logo} // Use your local logo image
            alt="LivAmaze Logo"
            className="d-inline-block align-top ms-md-0 me-md-3"
            width={130}
          />
        </Navbar.Brand>
        {isLoggedIn && (
          <OverlayTrigger placement="left" overlay={tooltip}>
            <h4>Welcome</h4>
          </OverlayTrigger>
        )}
      </Container>
    </Navbar>
  );
}
