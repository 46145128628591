import React, { useState } from "react";
import { Button } from "react-bootstrap";
import LoginForm from "../Login/LoginForm";
import ModalForm from "../Modal/ModalForm";

function TagLine() {
  const [showLoginForm, setShowLoginForm] = useState(false);

  function handleClick() {
    setShowLoginForm(true);
  }

  function handleClose() {
    setShowLoginForm(false);
  }

  return (
    <div style={{ background: "rgb(245, 245, 245)" }}>
      {/* Render Modal component conditionally */}
      <ModalForm
        show={showLoginForm}
        onClose={handleClose}
        title="Get Your Design"
      >
        <LoginForm />
      </ModalForm>

      <div className="container">
        <div className="row">
          <div className="col-12 pt-5 pb-3">
            <h1>Indulge in Your Dream Home Design with LivAmaze</h1>
            <p>
              Experience the epitome of home design with our signature Livspace
              touch. Our commitment to blending aesthetics with practicality
              ensures that your home exudes timeless elegance while serving your
              daily needs effortlessly.
            </p>
            <Button
              style={{ backgroundColor: "rgba(92, 92, 221, 1)" }}
              className="rounded-pill px-3 "
              size="lg"
              onClick={handleClick}
            >
              BOOK FREE CONSULTATION
            </Button>{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TagLine;
