import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import ModalForm from "../../shared/Modal/ModalForm";
import LoginForm from "../../shared/Login/LoginForm";

function DreamHouse(props) {
  const [showLoginForm, setShowLoginForm] = useState(false);

  useEffect(() => {
    setShowLoginForm(props.showLogin);
  }, [props.showLogin]);

  function handleClick() {
    setShowLoginForm(true);
  }
  function handleClose() {
    props.setShowLoginFromSide(false);
    setShowLoginForm(false);
  }

  return (
    <div style={{ background: "rgba(0,0,0,0.25)", marginBottom: "20px" }}>
      <ModalForm
        show={showLoginForm}
        onClose={handleClose}
        title="Get Your Design"
      >
        <LoginForm />
      </ModalForm>
      <Container fluid className="py-5">
        <Row>
          <Col className="text-center mt-5">
            <h1 className="fw-bold mb-3" style={{ fontSize: "2.0rem" }}>
              <span style={{ color: "yellow" }}>Your dream home</span> is just
              one click away
            </h1>

            <p className="mb-0">
              Book a free consultation with our expert interior designers.
            </p>
            <Button
              style={{ backgroundColor: "rgba(92, 92, 221, 1)" }}
              size="lg"
              className="rounded-pill px-3 mt-5 mb-5"
              onClick={handleClick}
            >
              START YOUR DESIGN JOURNEY
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default DreamHouse;
