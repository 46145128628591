import React, { useState } from "react";
import { Button, Card, FormCheck, CardImg } from "react-bootstrap";
import essential from "../../../Assets/img/Hotels/IMG-20240302-WA0065.jpg";

function WardrobeType(props) {
  const { handleBack, handleNext, setSelectedType } = props;
  const [selected, setSelected] = useState("");
  const handleRadioChange = (type) => {
    setSelected(type); // Update state on radio change
    setSelectedType(type); // Pass selected value to parent component
  };

  return (
    <div>
      <h3 className="mb-3 ">Select the type of wardrobe</h3>

      <div>
        <Card
          className={`mb-3 p-3 d-flex flex-fill align-items-start ${
            selected === "sliding" ? "border border-primary" : ""
          }`} // Conditional class for border on selected card
          onClick={() => handleRadioChange("sliding")}
        >
          <div className="text-start">
            <FormCheck
              type="radio"
              id={`sliding-radio`}
              label={`Sliding`}
              name="radioGroup"
              checked={selected === "sliding"} // Set checked based on state
            />
            {selected === "sliding" && ( // Use && for conditional rendering
              <div>
                <p>
                  Movable doors that slide horizontally along a metal rail and
                  save floor space.
                </p>
                <CardImg variant="top" src={essential} />
                <p>Make a style statement even in a compact space.</p>
              </div>
            )}
          </div>
        </Card>

        <Card
          className={`mb-3 p-3 d-flex flex-fill align-items-start ${
            selected === "swing" ? "border border-primary" : ""
          }`} // Conditional class for border on selected card
          onClick={() => handleRadioChange("swing")}
        >
          <div className="text-start">
            <FormCheck
              type="radio"
              id={`swing-radio`}
              label={`Swing`}
              name="radioGroup"
              checked={selected === "swing"} // Set checked based on state
            />
            {selected === "swing" && ( // Use && for conditional rendering
              <div>
                <p>
                  Movable doors that swing out to open, giving better visibility
                  and storage space.
                </p>
                <CardImg variant="top" src={essential} />
                <p>A cost-effective option that never goes out of style.</p>
              </div>
            )}
          </div>
        </Card>
      </div>

      <div className="d-flex justify-content-center align-items-center mb-3">
        <Button
          variant="primary"
          onClick={handleBack}
          className="rounded-pill mr-3  mx-3 mb-3"
        >
          Back
        </Button>
        <Button
          variant="primary"
          onClick={handleNext}
          className="rounded-pill mr-3 ml-3 mb-3"
        >
          Continue
        </Button>
      </div>
    </div>
  );
}

export default WardrobeType;
