import React, { useState } from "react";
import { Button, Card, FormCheck, CardImg, Form } from "react-bootstrap";
import essential from "../../../Assets/img/Hotels/IMG-20240302-WA0065.jpg";

function WardrobeFinish(props) {
  const td1 = {
    display: "flex",
    alignItems: "flex-start",
    width: "100px",
  };

  const { handleBack, handleNext, setSelectedFinish } = props;
  const [selected, setSelected] = useState("");
  const handleRadioChange = (finish) => {
    setSelected(finish); // Update state on radio change
    setSelectedFinish(finish); // Pass selected value to parent component
  };

  return (
    <div>
      <h3 className="mb-3">Select your preferred finish</h3>

      <div className="mt-3">
        <Card
          className={`mb-3 p-3 d-flex flex-fill align-items-start ${
            selected === "standard" ? "border border-primary" : ""
          }`} // Conditional class for border on selected card
          onClick={() => handleRadioChange("standard")}
        >
          <div key="standard-radio" className="mb-0 text-start">
            <FormCheck
              type="radio"
              id="standard-radio"
              label="Standard - Laminate"
              name="radioGroup"
              checked={selected === "standard"} // Set checked based on state
            />
          </div>
          {selected === "standard" && ( // Use && for conditional rendering
            <div>
              <CardImg variant="top" src={essential} />
              <table>
                <tbody>
                  <tr>
                    <td style={td1}>Price</td>
                    <td>: $$</td>
                  </tr>
                  <tr>
                    <td style={td1}>Pro Tip</td>
                    <td>
                      Looking for a seamless finish that sits well with every
                      interior? This one's for you.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </Card>

        <Card
          className={`mb-3 p-3 d-flex flex-fill align-items-start ${
            selected === "premium" ? "border border-primary" : ""
          }`} // Conditional class for border on selected card
          onClick={() => handleRadioChange("premium")}
        >
          <div key="premium-radio" className="mb-0 text-start">
            <FormCheck
              type="radio"
              id="premium-radio"
              label="Premium - Membrane"
              name="radioGroup"
              checked={selected === "premium"} // Set checked based on state
            />
          </div>
          {selected === "premium" && ( // Use && for conditional rendering
            <div>
              <CardImg variant="top" src={essential} />
              <table>
                <tbody>
                  <tr>
                    <td style={td1}>Price</td>
                    <td>: $$</td>
                  </tr>
                  <tr>
                    <td style={td1}>Pro Tip</td>
                    <td>
                      Looking for a seamless finish that sits well with every
                      interior? This one's for you.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </Card>

        <Card
          className={`mb-3 p-3 d-flex flex-fill align-items-start ${
            selected === "luxe" ? "border border-primary" : ""
          }`} // Conditional class for border on selected card
          onClick={() => handleRadioChange("luxe")}
        >
          <div key="premium-radio" className="mb-0 text-start">
            <FormCheck
              type="radio"
              id="premium-radio"
              label="Luxe - Acrylic"
              name="radioGroup"
              checked={selected === "luxe"} // Set checked based on state
            />
          </div>
          {selected === "luxe" && ( // Use && for conditional rendering
            <div>
              <CardImg variant="top" src={essential} />
              <table>
                <tbody>
                  <tr>
                    <td style={td1}>Price</td>
                    <td>: $$</td>
                  </tr>
                  <tr>
                    <td style={td1}>Pro Tip</td>
                    <td>
                      Looking for a seamless finish that sits well with every
                      interior? This one's for you.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </Card>
      </div>

      <div className="d-flex justify-content-center align-items-center mb-3">
        <Button
          variant="primary"
          onClick={handleBack}
          className="rounded-pill mr-3  mx-3 mb-3"
        >
          Back
        </Button>
        <Button
          variant="primary"
          onClick={handleNext}
          className="rounded-pill mr-3 ml-3 mb-3"
        >
          Continue
        </Button>
      </div>
    </div>
  );
}

export default WardrobeFinish;
