import React, { useState } from "react";
import { Card, Row, Col, Button, Form } from "react-bootstrap";
import { LuMinusCircle } from "react-icons/lu";
import { TiPlus } from "react-icons/ti";

function SelectRoom(props) {
  const [livingRoom, setLivingRoom] = useState(1);
  const [kitchen, setKitchen] = useState(1);
  const [bedroom, setBedroom] = useState(1);
  const [bathroom, setBathroom] = useState(1);
  const [dining, setDining] = useState(1);
  const { handleNext, handleBack } = props;

  const rooms = [
    { name: "Living Room", count: livingRoom, setter: setLivingRoom },
    { name: "Kitchen", count: kitchen, setter: setKitchen },
    { name: "Bedroom", count: bedroom, setter: setBedroom },
    { name: "Bathroom", count: bathroom, setter: setBathroom },
    { name: "Dining", count: dining, setter: setDining },
  ];

  const handleDecrement = (setter) => {
    setter((prev) => Math.max(prev - 1, 0));
  };

  const handleIncrement = (setter) => {
    setter((prev) => prev + 1);
  };

  const customBtn = {
    marginTop: "-0.3rem",
  };

  return (
    <div style={{ marginTop: "10vh" }}>
      <h2 className="mb-3 text-center">
        Select the rooms you’d like us to design
      </h2>
      <Form className="d-flex flex-column align-items-center justify-content-center">
        {rooms.map((room, index) => (
          <Card key={index} className="mb-3 p-3" style={{ width: "400px" }}>
            <Row className="align-items-center">
              <Col className="text-start" style={{ maxWidth: "200px" }}>
                {room.name}
              </Col>
              <Col xs="auto" className="d-flex align-items-center">
                <Button
                  variant="transparent"
                  size="sm"
                  className="me-2"
                  onClick={() => handleDecrement(room.setter)}
                  disabled={room.count === 0}
                  style={customBtn}
                >
                  <LuMinusCircle />
                </Button>
                <span>{room.count}</span>
                <Button
                  variant="transparent"
                  size="sm"
                  className="ms-3"
                  onClick={() => handleIncrement(room.setter)}
                  style={customBtn}
                >
                  <TiPlus />
                </Button>
              </Col>
            </Row>
          </Card>
        ))}
        <div className="d-flex justify-content-center align-items-center mb-3">
          <Button
            variant="primary"
            onClick={handleBack}
            className="rounded-pill mr-3  mx-3 mb-3"
          >
            Back
          </Button>
          <Button
            variant="primary"
            onClick={handleNext}
            className="rounded-pill mr-3 ml-3 mb-3"
          >
            Continue
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default SelectRoom;
