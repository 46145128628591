import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { MdCall } from "react-icons/md";
import { IoLogoWhatsapp } from "react-icons/io";

function ContactUs() {
  return (
    <>
      <Container fluid className="py-5 bg-light">
        <Row>
          <Col className="text-center mt-5">
            <h1 className="fw-bold mb-3">Connect with us</h1>
            <p className="mb-0">
              Reach out on WhatsApp or give us a call for the best home design
              experience.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col
            sm={6}
            md={4}
            lg={3}
            className="mb-3 d-flex justify-content-center"
          >
            <Button
              href={`tel:+91 9789966842`}
              target="_blank"
              style={{ backgroundColor: "rgba(92, 92, 221, 1)", width: "100%" }}
              size="lg"
              className="rounded-pill d-flex justify-content-center align-items-center"
            >
              <MdCall className="me-2" style={{ fontSize: "24px" }} />
              CALL NOW
            </Button>
          </Col>
          <Col
            sm={6}
            md={4}
            lg={3}
            className="mb-3 d-flex justify-content-center"
          >
            <Button
              href={`https://wa.me/919789966842?text="Hi, I need help with my interior.`}
              target="_blank"
              style={{ backgroundColor: "rgba(92, 92, 221, 1)", width: "100%" }}
              size="lg"
              className="rounded-pill d-flex justify-content-center align-items-center"
            >
              <IoLogoWhatsapp className="me-2" style={{ fontSize: "24px" }} />
              WHATSAPP
            </Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ContactUs;
