import React, { useState } from "react";
// import logo from "../../../Assets/img/livAmaze.avif";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import LoginForm from "../../shared/Login/LoginForm";
// import Button from "react-bootstrap/Button";
import Design1 from "../../../Assets/img/Design/Design_1.jpg";
import Design2 from "../../../Assets/img/Design/Design_2.jpg";
import Design3 from "../../../Assets/img/Design/Design_3.jpg";
import Design4 from "../../../Assets/img/Design/Design_4.jpg";

import "./Design.css";
import ModalForm from "../../shared/Modal/ModalForm";

function Design() {
  const [showLoginForm, setShowLoginForm] = useState(false);

  function handleClick() {
    setShowLoginForm(true);
  }

  function handleClose() {
    setShowLoginForm(false);
  }
  const imgData = [
    {
      src: Design1,
      alt: "Image 1",
      title: "Craft Your Home Oasis",
      description: "Surround yourself in the beauty and comfort you deserve.",
    },
    {
      src: Design2,
      alt: "Image 2",
      title: "Serene Sanctuary",
      description: "Embrace tranquility and unwind in your personal haven.",
    },
    {
      src: Design3,
      alt: "Image 3",
      title: "Craft a  Room They'll Love",
      description: "Captures the essence of creating a guest room.",
    },
    {
      src: Design4,
      alt: "Image 4",
      title: "Beautiful Washroom",
      description: "Unwind and recharge in your personal spa haven.",
    },
    // Add more image objects here as needed
  ];
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div>
      <ModalForm
        show={showLoginForm}
        onClose={handleClose}
        title="Get Your Design"
      >
        <LoginForm />
      </ModalForm>
      <Container className="home-for-every-style mb-5">
        <h2>Homes for every style</h2>
        <p className="content">
          Superior finishes, trendy designs and quality modules at affordable
          prices.
        </p>

        <div>
          <Carousel
            className="carousel-crd"
            responsive={responsive}
            arrows={false}
            swipeable={true}
            renderButtonGroupOutside={true}
          >
            {imgData.map((data, index) => (
              <Card
                key={index}
                className="card mb-5"
                style={{
                  width: "18rem",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <Card.Img
                  variant="top"
                  src={data.src}
                  className="product--image"
                  alt={data.alt || `Image ${index + 1}`}
                />
                <Card.Body>
                  <Card.Title className="fw-bold">
                    {data.title || `Card Title ${index + 1}`}
                  </Card.Title>
                  <Card.Text>
                    {data.description || "Some quick example text..."}
                  </Card.Text>
                  <Button
                    // variant="light"
                    className="rounded-pill px-3"
                    size="md"
                    style={{
                      backgroundColor: "rgba(92, 92, 221, 1)",
                      color: "white",
                    }}
                    onClick={handleClick}
                  >
                    BOOK FREE CONSULTATION
                  </Button>{" "}
                </Card.Body>
              </Card>
            ))}
          </Carousel>
        </div>
      </Container>
    </div>
  );
}

export default Design;
