import React, { useState } from "react";
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";

function CalculateLayout(props) {
  const [selectedCard, setSelectedCard] = useState(null);
  const { setSelectedKitchenLayout, handleNext } = props;

  const handleChange = () => {
    setSelectedKitchenLayout(selectedCard);
    handleNext();
  };

  const cardsData = [
    {
      id: 1,
      image:
        "https://as1.ftcdn.net/v2/jpg/05/65/49/44/1000_F_565494411_I2uUanw2CqAoWRROhKdThGvmdtyYMsWh.jpg",
      text: "L-Shaped",
    },
    {
      id: 2,
      image:
        "https://as1.ftcdn.net/v2/jpg/05/65/49/44/1000_F_565494411_I2uUanw2CqAoWRROhKdThGvmdtyYMsWh.jpg",
      text: "Straight",
    },
    {
      id: 3,
      image:
        "https://as1.ftcdn.net/v2/jpg/05/65/49/44/1000_F_565494411_I2uUanw2CqAoWRROhKdThGvmdtyYMsWh.jpg",
      text: "U-Shaped",
    },
  ];

  const cardsData2 = [
    {
      id: 4,
      image:
        "https://as1.ftcdn.net/v2/jpg/05/65/49/44/1000_F_565494411_I2uUanw2CqAoWRROhKdThGvmdtyYMsWh.jpg",
      text: "Parallel",
    },
  ];

  const handleCardSelect = (id) => {
    setSelectedCard(id);
  };

  return (
    <div style={{ overflowY: "auto", overflowX: "hidden" }}>
      <h1 className="text-center">Select the layout of your kitchen</h1>
      <Container fluid>
        <Row className="justify-content-center">
          {cardsData.map((card, index) => (
            <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
              <Card className="h-100" onClick={() => handleCardSelect(card.id)}>
                <Form.Check
                  type="radio"
                  id={`default-radio-${index}`}
                  checked={selectedCard === card.id}
                  className="position-absolute start-0 mt-2 ms-2"
                />
                <Card.Img variant="top" src={card.image} />
                <Card.Body>
                  <Card.Text className="text-center font-weight-bold">
                    {card.text}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <Container fluid>
        <Row className="justify-content-center mt-4">
          {cardsData2.map((card, index) => (
            <Col key={index} xs={12} sm={6} md={4} lg={3} className="mb-4">
              <Card className="h-100" onClick={() => handleCardSelect(card.id)}>
                <Form.Check
                  type="radio"
                  id={`default-radio-${index}`}
                  checked={selectedCard === card.id}
                  className="position-absolute start-0 mt-2 ms-2"
                />
                <Card.Img variant="top" src={card.image} />
                <Card.Body>
                  <Card.Text className="text-center font-weight-bold">
                    {card.text}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <div className="text-center mt-4">
        <Button
          className="rounded-pill"
          variant="primary"
          onClick={handleChange}
        >
          Continue
        </Button>
      </div>
    </div>
  );
}

export default CalculateLayout;
