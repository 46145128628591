import React from "react";
import "./sidebar.css";
import { FaWhatsapp } from "react-icons/fa";
import { MdCall } from "react-icons/md";
import { RiCustomerService2Line } from "react-icons/ri";

function Sidebar({ setShowLoginFromSidebar }) {
  function call() {
    window.location.href = "tel://9789966842";
  }

  function whatsapp() {
    window.location.href =
      'https://wa.me/919789966842?text="Hi, I need help with my Interiors.';
  }
  function callBack() {
    setShowLoginFromSidebar(true);
  }
  return (
    <>
      <div className="button-list">
        <button
          onClick={whatsapp}
          className="wa-color icon-button"
          aria-label="WhatsApp"
        >
          <FaWhatsapp />
        </button>
        <button
          onClick={call}
          className="call-color icon-button"
          aria-label="Call"
        >
          <MdCall />
        </button>
        <button
          onClick={callBack}
          className="connect-color icon-button"
          aria-label="Customer Service"
        >
          <RiCustomerService2Line />
        </button>
      </div>
    </>
  );
}

export default Sidebar;
