import React from "react";
import "./home.css";
// import ReasonToChoose from "./reasonToChoose/reasonToChoose";
import TagLine from "../shared/tagLine/TagLine";
import Design from "./designs/Design";
import PriceCalculator from "../calculateForm/priceCalculator/PriceCalculator";
import Offers from "./whatWeOffer/offers";
import TrustedPartners from "./trustedPartners/TrustedPartners";
import TestToBeBest from "./testToBeBest/TestToBeBest";
import HowItWorks from "./howitworks/howitworks";
import ContactUs from "../shared/contactUs/ContactUs";
import DreamHouse from "./dreamHouse/DreamHouse";
import Gallery from "./gallery/Gallery";
import Carousel from "react-multi-carousel";
import { useEffect, useState } from "react";
import Chart from "../../Assets/banner-images/chart.gif";
import Banner1 from "../../Assets/banner-images/1.webp";
import Banner2 from "../../Assets/banner-images/2.webp";
import Banner3 from "../../Assets/banner-images/3.webp";
import Banner4 from "../../Assets/banner-images/4.webp";
import Sidebar from "./sideBar/sidebar";

function Home() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  function setShowLoginFromSide(data) {
    setShowLogin(data);
  }

  useEffect(() => {
    const storedValues = JSON.parse(sessionStorage.getItem("loginValues"));
    if (storedValues) {
      console.log(storedValues);
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, []);

  return (
    <div className="background-container">
      <Sidebar setShowLoginFromSidebar={setShowLoginFromSide} />
      <Carousel
        additionalTransfrom={0}
        autoPlay
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        containerClass=""
        draggable
        removeArrowOnDeviceType={'mobile'}
        focusOnSelect={false}
        infinite
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={true}
        renderButtonGroupOutside={true}
        renderDotsOutside={true}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024
            },
            items: 1
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0
            },
            items: 1
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464
            },
            items: 1
          }
        }}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        <img
          alt="banner-1"
          src={Banner1}
          style={{
            display: 'block',
            height: '100%',
            margin: 'auto',
            width: '100%'
          }}
        />
        <img
          alt="banner-2"
          src={Banner2}
          style={{
            display: 'block',
            height: '100%',
            margin: 'auto',
            width: '100%'
          }}
        />
        <img
          alt="banner-3"
          src={Banner3}
          style={{
            display: 'block',
            height: '100%',
            margin: 'auto',
            width: '100%'
          }}
        />
        <img
          alt="banner-4"
          src={Banner4}
          style={{
            display: 'block',
            height: '100%',
            margin: 'auto',
            width: '100%'
          }}
        />
      </Carousel>
      {/* <ReasonToChoose /> */}
      <img src={Chart} alt="Flowchart" width="100%" />
      <TagLine />
      <Design />
      <PriceCalculator />
      <Offers />
      <Gallery />
      <TrustedPartners />
      <TestToBeBest />
      <HowItWorks />
      <ContactUs />
      <DreamHouse showLogin={showLogin} setShowLoginFromSide={setShowLoginFromSide} />
    </div>
  );
}

export default Home;
