import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Home from "../../../Assets/img/Living_Room/Home_9.jpg";
import Kitchen from "../../../Assets/img/Kitchen/Kitchen_3.jpg";
import Wardobe from "../../../Assets/img/Wardobe/Wardobe_1.jpg";

function PriceCalculator() {
  const [showForm, setShowForm] = useState(""); // State to manage the visibility of the form
  const navigate = useNavigate();
  const handleCalculateClick = (value) => {
    if (value === "Home") {
      navigate("/calculateRoom");
    } else if (value === "Kitchen") {
      navigate("/calculateKitchen");
    } else {
      navigate("/calculateWardrobe");
    }
  };

  return (
    <div
      style={{
        marginTop: "10vh",
        marginBottom: "10vh",
        backGround: "grey",
      }}
    >
      <Container className="home-for-every-style pb-3 bg-grey">
        <h2 className="pt-3 mt-3">Price Calculator</h2>
        <p className="pb-3 mb-3">
          Estimate the potential expenses associated with renovating your indoor
          spaces.
        </p>
        <Row className="pb-3">
          <Col
            style={{
              marginTop: "2rem",
              minWidth: "300px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card className="d-grid gap-2">
              <Card.Img
                src={Home}
                alt="Monochromatic Grey 2 BHK"
                style={{ objectFit: "cover", height: "250px" }}
              />
              <Card.Body>
                <Card.Title className="fw-bold">Home</Card.Title>
                <Card.Text>Estimate Your Entire Home Interior Budget</Card.Text>
                <Button
                  style={{
                    backgroundColor: "rgba(92, 92, 221, 1)",
                    color: "white",
                  }}
                  size="md"
                  className="rounded-pill px-3"
                  onClick={() => handleCalculateClick("Home")}
                >
                  CALCULATE
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col
            style={{
              marginTop: "2rem",
              minWidth: "300px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card className="d-grid gap-2">
              <Card.Img
                src={Kitchen}
                alt="Monochromatic Grey 2 BHK"
                style={{ objectFit: "cover", height: "250px" }}
              />
              <Card.Body>
                <Card.Title className="fw-bold">Kitchen</Card.Title>
                <Card.Text>Estimate Your Entire Home Interior Budget</Card.Text>
                <Button
                  style={{
                    backgroundColor: "rgba(92, 92, 221, 1)",
                    color: "white",
                  }}
                  size="md"
                  className="rounded-pill px-3"
                  onClick={() => handleCalculateClick("Kitchen")}
                >
                  CALCULATE
                </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col
            style={{
              marginTop: "2rem",
              minWidth: "300px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card className="d-grid gap-2">
              <Card.Img
                src={Wardobe}
                alt="Monochromatic Grey 2 BHK"
                style={{ objectFit: "cover", height: "250px" }}
              />
              <Card.Body>
                <Card.Title className="fw-bold">Wardobe</Card.Title>
                <Card.Text>Estimate Your Entire Home Interior Budget</Card.Text>
                <Button
                  style={{
                    backgroundColor: "rgba(92, 92, 221, 1)",
                    color: "white",
                  }}
                  size="md"
                  className="rounded-pill px-3"
                  onClick={() => handleCalculateClick("Wardobe")} // Call handleCalculateClick when CALCULATE button is clicked
                >
                  CALCULATE
                </Button>
              </Card.Body>
            </Card>
          </Col>
          {/* Repeat similar structure for other cards */}
        </Row>
      </Container>

      <div
        className="modal fade"
        id="exampleModalLong"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLongTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLongTitle">
                Modal title
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">...</div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary">
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PriceCalculator;
