import React from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import "./Modal.css";

function LoggedInComponent({ onPhoneChangeClick }) {
  return (
    <div className="">
      {" "}
      <p className="text-center">You are already logged in.</p>
      <Container>
        <Row>
          <Button
            className="rounded-pill px-3 ml-3 text-center"
            size="md"
            style={{
              backgroundColor: "rgba(92, 92, 221, 1)",
              color: "white",
            }}
            onClick={onPhoneChangeClick}
          >
            Change Phone Number
          </Button>
        </Row>
      </Container>
    </div>
  );
}

export default LoggedInComponent;
