import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./Modal.css";
import LoggedInComponent from "./LoggedInComponent";

const ModalForm = ({ show, onClose, children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [hasJustLoggedIn, setHasJustLoggedIn] = useState(false);

  // Check session storage on every modal open (useEffect with empty dependency array)
  useEffect(() => {
    const storedValues = JSON.parse(sessionStorage.getItem("loginValues"));
    if (show) {
      if (storedValues && storedValues.phoneNumber) {
        setIsLoggedIn(true);
      } else {
        setIsLoggedIn(false); // Ensure isLoggedIn is reset even if modal was previously closed while logged in
      }
    }
  }, [show]);

  const handlePhoneChangeClick = () => {
    setIsLoggedIn(false);
  };
  const handleLogin = () => {
    setHasJustLoggedIn(true);
  };

  return (
    <Modal show={show} onHide={onClose} centered>
      <div className="modal-content">
        <Modal.Header closeButton>Get a call back from us</Modal.Header>
        <Modal.Body>
          {hasJustLoggedIn ? (
            <span className="d-flex justify-content-center align-items-center">
              We will contact you shortly!
            </span>
          ) : isLoggedIn ? (
            <LoggedInComponent onPhoneChangeClick={handlePhoneChangeClick} />
          ) : (
            React.cloneElement(children, { onLogin: handleLogin }) // Pass the handleLogin callback to children // Render any provided children if not logged in
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default ModalForm;
