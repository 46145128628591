import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
// import logo from "../../Assets/img/home.jpg";
import "./howitsWorks.css";
import { SlUserFemale } from "react-icons/sl";
import { GiSofa } from "react-icons/gi";
import { FaPaperPlane } from "react-icons/fa";
import { MdEmojiTransportation } from "react-icons/md";
import ModalForm from "../../shared/Modal/ModalForm";
import LoginForm from "../../shared/Login/LoginForm";

function HowItWorks() {
  const [showLoginForm, setShowLoginForm] = useState(false);

  function handleClick() {
    setShowLoginForm(true);
  }

  function handleClose() {
    setShowLoginForm(false);
  }
  return (
    <div className="how-it-works">
      <ModalForm
        show={showLoginForm}
        onClose={handleClose}
        title="Get Your Design"
      >
        <LoginForm />
      </ModalForm>
      <Container>
        <Row className="mt-5">
          <Col xs={12}>
            <h1 className="text-center">How it Works?</h1>
          </Col>
        </Row>
        <Row className="mt-5 pt-5 justify-content-center">
          {steps.map((step, index) => (
            <Col
              xs={12}
              md={4}
              key={index}
              className="d-flex flex-column align-items-center mb-5"
            >
              <div className="circle">{step.icon}</div>
              <h5 className="text-center mt-3 mb-0">{step.title}</h5>
              {step.description && (
                <p className="text-center">{step.description}</p>
              )}
            </Col>
          ))}
        </Row>
      </Container>
      <Button
        style={{ backgroundColor: "rgba(92, 92, 221, 1)" }}
        size="lg"
        className="rounded-pill px-3 mt-5 mb-5"
        onClick={handleClick}
      >
        BOOK FREE CONSULTATION
      </Button>
    </div>
  );
}

const steps = [
  {
    icon: <SlUserFemale className="icon" />,
    title: "Meet a Designer",
  },
  {
    icon: <GiSofa className="icon" />,
    title: "(5% payment) Book a Renovation",
  },
  {
    icon: <FaPaperPlane className="icon" />,
    title: "(50% payment) Execution Begins",
  },
  {
    icon: <SlUserFemale className="icon" />,
    title: "(100% payment) Final Installations",
  },
  {
    icon: <MdEmojiTransportation className="icon" />,
    title: "Move in and Enjoy!",
  },
];

export default HowItWorks;
