import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./login.css";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import eventBus from "../eventBus";
import "firebase/database";
import { useAuth } from "../../../AuthProvider";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import OtpInput from "react-otp-input";

const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .matches(/^[0-9\-+ ]{8,15}$/, "Invalid phone number.*")
    .required("Phone number is required.*"),
});

const firebaseConfig = {
  apiKey: "AIzaSyC0AlWzrSdwXmho0GAm_Gc-2WJmM4nd8C8",
  authDomain: "livamaze-55a3e.firebaseapp.com",
  projectId: "livamaze-55a3e",
  storageBucket: "livamaze-55a3e.appspot.com",
  messagingSenderId: "821678515352",
  appId: "1:821678515352:web:cc8f572995a00861bf5ced",
  measurementId: "G-NG4JVK6H1H",
};

firebase.initializeApp(firebaseConfig);

function LoginForm({ onLogin }) {
  const { login } = useAuth();
  const [hideLoginForm, setHideLoginForm] = useState(false);
  const [otpStatus, setOtpStatus] = useState("");
  const [userInput, setUserInput] = useState({});
  const [otp, setOtp] = useState(null);

  const [showLogin, setShowLogin] = useState(true);

  const auth = getAuth();

  let text = "";
  if (otpStatus === "verified") {
    text = <p className="text-dark">OTP verified</p>;
  }
  if (otpStatus === "not-verified") {
    text = <p className="text-dark">Wrong OTP entered</p>;
  }
  if (otpStatus === "sent") {
    text = <p className="text-dark">OTP sent to {userInput.phoneNumber} </p>;
  }
  const handleSubmit = (values) => {
    setUserInput(values);
    sessionStorage.setItem("loginValues", JSON.stringify(values));
    onSignInSubmit(values.phoneNumber);
  };

  const verifyCaptcha = (phoneNumber) => {
    const button = document.getElementById("sign-in-button");
    button.disabled = true;
    button.innerHTML = "Loading...";

    window.recaptchaVerifier = new RecaptchaVerifier(auth, "sign-in-button", {
      size: "invisible",
      callback: (response) => {
        // reCAPTCHA solved, allow signInWithPhoneNumber.
        onSignInSubmit(phoneNumber);
      },
      badge: "none",
      defaultCountry: "IN",
    });
  };

  const onSignInSubmit = (phone) => {
    const phoneNumber = `+${phone}`;
    verifyCaptcha(phoneNumber);
    const appVerifier = window.recaptchaVerifier;

    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then((confirmationResult) => {
        setHideLoginForm(true);
        setOtpStatus("sent");
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        setHideLoginForm(false);
        if (otpStatus === "failed") {
          setTimeout(() => {
            setOtpStatus("failed");
          }, 5000);
        }
        console.log("err", error);

        const button = document.getElementById("sign-in-button");
        button.disabled = false;
        button.innerHTML = "Send OTP";
      });
  };

  const saveToFirebase = (userInput) => {
    const firestore = firebase.firestore();

    firestore
      .collection("users")
      .add(userInput)
      .then((docRef) => {
        console.log("Data stored successfully with ID: ", docRef.id);
      })
      .catch((error) => {
        console.error("Error storing data:", error);
      });
    login();
    console.log("userInput-fb", userInput);
  };

  const handleVerifyOTP = (e) => {
    e.preventDefault();
    const code = otp;
    const button = document.getElementById("verify-otp");
    button.disabled = true;
    button.innerHTML = "Please wait...";
    window.confirmationResult
      .confirm(code)
      .then((result) => {
        const user = result.user;
        saveToFirebase(userInput);

        sessionStorage.setItem("loginValues", JSON.stringify(userInput));
        // onLogin();
        setShowLogin(false);
        setOtpStatus("verified");;
        eventBus.publish("sessionStorageUpdated");
        button.innerHTML = "Verified";
        onLogin();
      })
      .catch((error) => {
        console.log("otp-error", error);
        button.disabled = false;
        button.innerHTML = "Verify OTP";
        setOtpStatus("not-verified");
      });
  };

  return (
    <div className="loginform">
      {showLogin && (
        <div className="login-card" id="loginform-card">
          <div>
            {otpStatus === "failed" && (
              <p className="text-dark">Error sending OTP</p>
            )}

            {!hideLoginForm ? (
              <Formik
                initialValues={{ phoneNumber: "" }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ handleSubmit, errors, touched, setFieldValue, values }) => (
                  <Form className="form-group" onSubmit={handleSubmit}>
                    <Form.Group controlId="phoneNumber">
                      <PhoneInput
                        country={"in"}
                        value={values.phoneNumber}
                        className="white-text"
                        onChange={(value) =>
                          setFieldValue("phoneNumber", value)
                        }
                        inputProps={{
                          name: "phoneNumber",
                          required: true,
                          className: `form-control ${
                            errors.phoneNumber && touched.phoneNumber
                              ? "is-invalid"
                              : ""
                          }`,
                          style: {
                            color: "black",
                          },
                        }}
                      />
                      <ErrorMessage
                        name="phoneNumber"
                        render={(msg) => (
                          <div
                            style={{
                              color: "red",
                              fontSize: "16px",
                              textAlign: "left",
                            }}
                          >
                            {msg}
                          </div>
                        )}
                      />
                    </Form.Group>
                    <Button id="sign-in-button" variant="primary" type="submit">
                      Send OTP
                    </Button>
                  </Form>
                )}
              </Formik>
            ) : (
              <div>
                {text}

                <div>
                  <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    // renderSeparator={<pre> </pre>}
                    inputStyle="otp-input"
                    containerStyle="otp-container"
                    shouldAutoFocus={true}
                    // inputType='number'
                    renderInput={(props) => <input {...props} />}
                  />
                  <Button
                    className="d-inline-block"
                    id="verify-otp"
                    variant="primary"
                    onClick={handleVerifyOTP}
                  >
                    Verify OTP
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default LoginForm;
