import React, { useState, useEffect } from "react";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
// import logo from "../../Assets/img/home.jpg";
import yourVideoSrc from "../../../Assets/img/pix.mp4";

function TestToBeBest() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isPortrait, setIsPortrait] = useState(
    window.innerWidth < window.innerHeight
  ); // Initial check

  useEffect(() => {
    const handleResize = () => {
      setIsPortrait(window.innerWidth < window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const togglePlay = () => {
    const video = document.getElementById("videoPlayer");
    if (isPlaying) {
      video.pause();
    } else {
      video.play();
    }
    setIsPlaying(!isPlaying);
  };
  return (
    <div className="mb-4 pb-4 bg-light">
      <Container className="mt-4 pt-4 px-2 ">
        <h1 className="fw-bold text-start ">Tested to be best</h1>
        <p className=" text-start ">
          A lot of our success in modular strength hinges on testing our
          cabinets.
        </p>
        <Row>
          <Col>
            {/* Tabs component with active tab set to "Profile" */}
            <Tabs
              defaultActiveKey="home"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="home" title="Home">
                {isPortrait ? ( // Display in stacked format for mobile
                  <Row>
                    <Col xs={12}>
                      <video
                        id="videoPlayer"
                        controls
                        src={yourVideoSrc}
                        autoPlay={false}
                        loop
                        className="embed-responsive-item d-block mx-auto mb-3" // Center video and add margin
                      />
                    </Col>
                    <Col xs={12}>
                      <h1>Drawer Cyclic Test</h1>
                      <p>Ensures extended life for your cabinet drawers.</p>
                    </Col>
                  </Row>
                ) : (
                  // Display side-by-side for larger screens
                  <Row>
                    <Col xs={6} className="p-0">
                      <video
                        id="videoPlayer"
                        controls
                        src={yourVideoSrc}
                        autoPlay={false}
                        loop
                        className="embed-responsive-item"
                      />
                    </Col>
                    <Col xs={6} className="p-0">
                      <h1>Drawer Cyclic Test</h1>
                      <p>Ensures extended life for your cabinet drawers.</p>
                    </Col>
                  </Row>
                )}
              </Tab>
              <Tab eventKey="profile" title="Profile">
                {isPortrait ? ( // Display in stacked format for mobile
                  <Row>
                    <Col xs={12}>
                      <video
                        id="videoPlayer"
                        controls
                        src={yourVideoSrc}
                        autoPlay={false}
                        loop
                        className="embed-responsive-item d-block mx-auto mb-3" // Center video and add margin
                      />
                    </Col>
                    <Col xs={12}>
                      <h1>Drawer Cyclic Test</h1>
                      <p>Ensures extended life for your cabinet drawers.</p>
                    </Col>
                  </Row>
                ) : (
                  // Display side-by-side for larger screens
                  <Row>
                    <Col xs={6} className="p-0">
                      <video
                        id="videoPlayer"
                        controls
                        src={yourVideoSrc}
                        autoPlay={false}
                        loop
                        className="embed-responsive-item"
                      />
                    </Col>
                    <Col xs={6} className="p-0">
                      <h1>Drawer Cyclic Test</h1>
                      <p>Ensures extended life for your cabinet drawers.</p>
                    </Col>
                  </Row>
                )}
              </Tab>
              <Tab eventKey="contact" title="Contact">
                {isPortrait ? ( // Display in stacked format for mobile
                  <Row>
                    <Col xs={12}>
                      <video
                        id="videoPlayer"
                        controls
                        src={yourVideoSrc}
                        autoPlay={false}
                        loop
                        className="embed-responsive-item d-block mx-auto mb-3" // Center video and add margin
                      />
                    </Col>
                    <Col xs={12}>
                      <h1>Drawer Cyclic Test</h1>
                      <p>Ensures extended life for your cabinet drawers.</p>
                    </Col>
                  </Row>
                ) : (
                  // Display side-by-side for larger screens
                  <Row>
                    <Col xs={6} className="p-0">
                      <video
                        id="videoPlayer"
                        controls
                        src={yourVideoSrc}
                        autoPlay={false}
                        loop
                        className="embed-responsive-item"
                      />
                    </Col>
                    <Col xs={6} className="p-0">
                      <h1>Drawer Cyclic Test</h1>
                      <p>Ensures extended life for your cabinet drawers.</p>
                    </Col>
                  </Row>
                )}
              </Tab>
            </Tabs>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TestToBeBest;
