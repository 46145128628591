import React from "react";
import { Container, Row, Col, Card, Image, Button } from "react-bootstrap";
import Uparwala from "../../../Assets/img/Uparwala/Uparwala_2.jpeg";
import { MdOutlineFileDownload } from "react-icons/md";

function TrustedPartners() {
  return (
    <div className="mb-4 pb-4">
      <Container className="mt-3 pt-3">
        <Row>
          <h1 className="fw-bold text-center px-3">Our Trusted Partners</h1>
          <p className="text-center px-3">
            Winning collaborations that produce winning designs.
          </p>
        </Row>
        <Row>
          <Col xs={12}>
            <marquee
              behavior="scroll"
              style={{ color: "red", fontSize: "1.5em" }}
            >
              <Row className="d-flex justify-content-between">
                {[...Array(6)].map((_, index) => (
                  <Col
                    key={index}
                    xs={2}
                    className="d-flex justify-content-center"
                  >
                    <Card style={{ width: "100%", height: "100%" }}>
                      <Image src={Uparwala} alt="akshay" fluid />
                    </Card>
                  </Col>
                ))}
              </Row>
            </marquee>
          </Col>
        </Row>
        <Row style={{ border: "2px solid #fce195", height: "20%" }}>
          <Col xs={12} md={6} className="p-0">
            <Image
              src={Uparwala}
              alt="akshay"
              fluid
              style={{ width: "100%" }}
            />
          </Col>
          <Col xs={12} md={6} style={{ backgroundColor: "#a9c8de" }}>
            <div className="mt-5 pt-5 px-3 d-flex flex-column justify-content-center align-items-center">
              <div className="text-center w-100">
                <h1>Download home interior guide</h1>
                <p>
                  Don't forget to consider these fundamental design guidelines
                  to know before you start interior designing!
                </p>
                {/* <Button
                  variant="danger"
                  size="lg"
                  className="rounded-pill px-3"
                >
                  <MdOutlineFileDownload /> Download Now
                </Button> */}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default TrustedPartners;
