import React from "react";
import Card from "react-bootstrap/Card";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

function Measurement(props) {
  const { selectedKitchenLayout, handleNext, handleBack } = props;
  const cardsData = [
    {
      id: 1,
      image:
        "https://as1.ftcdn.net/v2/jpg/05/65/49/44/1000_F_565494411_I2uUanw2CqAoWRROhKdThGvmdtyYMsWh.jpg",
      text: "L-Shaped",
    },
  ];

  const layoutConfig = {
    1: { defaultValues: [3, 8], labels: ["A", "B"] },
    2: { defaultValues: [8], labels: ["A"] },
    3: { defaultValues: [10, 11, 10], labels: ["A", "B", "C"] },
    default: { defaultValues: [8, 8], labels: ["A", "B"] },
  };

  const config = layoutConfig[selectedKitchenLayout] || layoutConfig.default;

  const renderBasedKitchenLayout = () => (
    <>
      <Card style={{ width: "40rem" }} className="text-center mx-auto">
        <Card.Img variant="top" src={cardsData[0].image} />
      </Card>
      <div className="text-center mt-3" style={{ backgroundColor: "yellow" }}>
        Standard size has been set for your convenience
      </div>
      {config.labels.map((label, index) => (
        <div
          className="d-flex align-items-center justify-content-center mt-3"
          key={index}
        >
          <span className="mr-2 pe-2">{label}</span>
          <Form.Select
            size="sm"
            defaultValue={config.defaultValues[index]}
            style={{ maxWidth: "50%", minWidth: "50px" }}
            className="mr-2 ml-2"
          >
            {[...Array(12).keys()].map((i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </Form.Select>
          <span className="ml-2 ps-2">ft</span>
        </div>
      ))}
    </>
  );

  return (
    <div>
      <h2 className="fw-bold text-center">
        Now review the measurements for accuracy
      </h2>
      {renderBasedKitchenLayout()}
      <div className="d-flex justify-content-center align-items-center mb-3">
        <Button
          variant="primary"
          onClick={handleBack}
          className="rounded-pill mr-3 mx-3 mb-3"
        >
          Back
        </Button>
        <Button
          variant="primary"
          onClick={handleNext}
          className="rounded-pill mr-3 ml-3 mb-3"
        >
          Continue
        </Button>
      </div>
    </div>
  );
}

export default Measurement;
