import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Package1 from "../../../Assets/img/Package/Package_1.jpeg";
import Package2 from "../../../Assets/img/Package/Package_2.jpg";
import Package3 from "../../../Assets/img/Package/Package_3.jpg";
import { TiTickOutline } from "react-icons/ti";

const packages = [
  {
    type: "essential",
    label: "Essential(₹₹)",
    description:
      "A range of essential home interior solutions that's perfect for all your needs.",
    imgSrc: Package1,
    features: ["Affordable pricing", "Convenient designs", "Basic accessories"],
  },
  {
    type: "premium",
    label: "Premium(₹₹₹)",
    description:
      "A range of premium home interior solutions that offer more luxury.",
    imgSrc: Package2,
    features: ["Premium pricing", "Luxury designs", "Advanced accessories"],
  },
  {
    type: "luxe",
    label: "Luxe(₹₹₹₹)",
    description:
      "A range of luxe home interior solutions for the ultimate experience.",
    imgSrc: Package3,
    features: ["Luxe pricing", "Exclusive designs", "Top-tier accessories"],
  },
];

function SelectPackage(props) {
  const { handleBack, handleNext } = props;
  const [selectedPackage, setSelectedPackage] = useState(null);

  const handlePackageSelect = (packageType) => {
    setSelectedPackage(packageType === selectedPackage ? null : packageType);
  };

  return (
    <Container style={{ marginTop: "10vh" }}>
      <h2 className="mt-5">Pick your package</h2>
      {packages.map((pkg) => (
        <Row className="mt-4" key={pkg.type}>
          <Col className="m-2 d-flex justify-content-center">
            <Card
              className={selectedPackage === pkg.type ? "border-primary" : ""}
              onClick={() => handlePackageSelect(pkg.type)}
              style={{ padding: "16px" }}
            >
              <Form>
                <Form.Check
                  inline
                  type={`radio`}
                  id={`default-radio-${pkg.type}`}
                  label={pkg.label}
                  checked={selectedPackage === pkg.type}
                  className="text-start fw-bold"
                />
                <br />
                {pkg.description}
              </Form>
              <Card.Body>
                <div style={{ marginBottom: "10px" }}>
                  <Card.Img variant="top" src={pkg.imgSrc} />
                </div>
                <Card.Text>
                  {pkg.features.map((feature, index) => (
                    <Row key={index}>
                      <Col className="text-start">
                        <TiTickOutline
                          style={{ fontSize: "20px", marginRight: "5px" }}
                        />{" "}
                        {feature}
                      </Col>
                    </Row>
                  ))}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ))}

      {/* Buttons */}
      <div className="d-flex justify-content-center align-items-center mb-3">
        <Button
          variant="primary"
          onClick={handleBack}
          className="rounded-pill mr-3  mx-3 mb-3"
        >
          Back
        </Button>
        <Button
          variant="primary"
          onClick={handleNext}
          className="rounded-pill mr-3 ml-3 mb-3"
        >
          Continue
        </Button>
      </div>
    </Container>
  );
}

export default SelectPackage;
