import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { FormCheck, Card, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

function WardrobeHeight(props) {
  const { handleNext, setSelectedHeight } = props;
  const [selectedRadio, setSelectedRadio] = useState(""); // State to track selected height

  const handleRadioChange = (heightValue) => {
    setSelectedRadio(heightValue); // Update state on radio change
    setSelectedHeight(heightValue); // Pass selected value to parent component
  };
  return (
    <div>
      <h3>What is the height of your wardrobe?</h3>
      {/* <h5>
        Want to know more.
        <span style={{ color: "red" }} onClick={handleShow}>
          {" "}
          Click here
        </span>
      </h5> */}

      <div className="px-5 mt-5">
        <Card
          className="mb-3 p-3 d-flex align-items-start"
          onClick={() => handleRadioChange("4")}
        >
          <FormCheck
            type="radio"
            id={`height-radio-${"4"}`} // Unique ID for each radio
            label="4 ft"
            name="radioGroup"
            checked={selectedRadio === "4"} // Set checked based on state
          />
        </Card>

        <Card
          className="mb-3 p-3 d-flex align-items-start"
          onClick={() => handleRadioChange("6")}
        >
          <FormCheck
            type="radio"
            id={`height-radio-${"6"}`} // Unique ID for each radio
            label="6 ft"
            name="radioGroup"
            checked={selectedRadio === "6"} // Set checked based on state
          />
        </Card>

        <Card
          className="mb-3 p-3 d-flex align-items-start"
          onClick={() => handleRadioChange("7")}
        >
          <FormCheck
            type="radio"
            id={`height-radio-${"7"}`} // Unique ID for each radio
            label="7 ft"
            name="radioGroup"
            checked={selectedRadio === "7"} // Set checked based on state
          />
        </Card>

        <Card
          className="mb-3 p-3 d-flex align-items-start"
          onClick={() => handleRadioChange("9")}
        >
          <FormCheck
            type="radio"
            id={`height-radio-${"9"}`} // Unique ID for each radio
            label="9 ft"
            name="radioGroup"
            checked={selectedRadio === "9"} // Set checked based on state
          />
        </Card>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button variant="primary" onClick={handleNext} className="rounded-pill">
          Continue
        </Button>
      </div>
    </div>
  );
}

export default WardrobeHeight;
