import React, { useState } from "react";
import { Container } from "react-bootstrap";
import SelectBhk from "../calculateRoom/SelectBhk";
import SelectPackage from "../calculateRoom/SelectPackage";
import SelectRoom from "../calculateRoom/SelectRoom";
import LoginForm from "../../shared/Login/LoginForm";
import CalculateLayout from "./CalculateLayout";
import Measurement from "./Measurement";
import Stepper from "react-stepper-horizontal";
import "../CalculateForm.css";

function CalculateKitchen() {
  const [step, setStep] = useState(1);
  const [selectedKitchenLayout, setSelectedKitchenLayout] = useState("");
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setStep(step + 1);
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
    setActiveStep(activeStep - 1);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 1:
        return (
          <>
            <CalculateLayout
              setSelectedKitchenLayout={setSelectedKitchenLayout}
              handleNext={handleNext}
            />
          </>
        );
      case 2:
        return (
          <>
            <Measurement
              selectedKitchenLayout={selectedKitchenLayout}
              handleBack={handleBack}
              handleNext={handleNext}
            />
          </>
        );
      case 3:
        return (
          <>
            <SelectPackage handleNext={handleNext} handleBack={handleBack} />
          </>
        );
      default:
        return (
          <div>
            <div className="background-image">
              <LoginForm />
            </div>
          </div>
        );
    }
  };
  return (
    <div style={{ marginTop: "100px", marginBottom: "20vh" }}>
      <Container className="d-flex justify-content-center">
        <div
          style={{
            boxShadow: " 0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
            padding: "2rem",
          }}
          className={step === 4 ? "background-image-calculate" : ""}
        >
          <Stepper
            steps={[
              { title: "Step 1" },
              { title: "Step 2" },
              { title: "Step 3" },
              { title: "Step 4" },
            ]}
            activeStep={activeStep}
          />
          {renderStepContent(step)}
        </div>
      </Container>
    </div>
  );
}

export default CalculateKitchen;
