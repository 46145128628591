import React, { useState } from "react";
import "./gallery.css"; // Import CSS file for styling
import Kitchen1 from "../../../Assets/img/Kitchen/Kitchen_1.jpg";
import Kitchen2 from "../../../Assets/img/Kitchen/Kitchen_2.jpg";
import Kitchen3 from "../../../Assets/img/Kitchen/Kitchen_3.jpg";
import Home_1 from "../../../Assets/img/Living_Room/Home_1.jpg";
import Home_2 from "../../../Assets/img/Living_Room/Home_20.jpg";
import Home_3 from "../../../Assets/img/Living_Room/Home_12.jpg";
import Ceiling_1 from "../../../Assets/img/Ceiling/Ceiling_1.jpeg";
import Ceiling_2 from "../../../Assets/img/Ceiling/Ceiling_2.jpeg";
import Ceiling_3 from "../../../Assets/img/Ceiling/Ceiling_5.jpeg";

function Gallery() {
  const [isHovered, setIsHovered] = useState(false); // State to manage hover

  return (
    <div className="gallery mt-5 mb-5">
      <h1 className="fw-bold text-center px-3">Gallery</h1>
      <h2 className="text-center px-3">
        "Photographs are return ticket to the glorious moments we had."
      </h2>
      <br />
      <br />
      <div className="content clearfix">
        {/* Add onMouseEnter and onMouseLeave handlers */}
        <div
          className={`cube-container ${isHovered ? "hovered" : ""}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="photo-cube">
            <img className="front" src={Home_1} alt="" />
            <div className="back photo-desc">
              <h3>Home</h3>
              <p>Where comfort and style come together seamlessly.</p>
            </div>
            {/* You can add images for left and right here */}
            <img className="left" src={Home_2} alt="" />
            <img className="right" src={Home_3} alt="" />
          </div>
        </div>
        <div
          className={`cube-container ${isHovered ? "hovered" : ""}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="photo-cube">
            <img className="front" src={Kitchen1} alt="" />
            <div className="back photo-desc">
              <h3>Kitchen</h3>
              <p>Transform your kitchen into a space you love.</p>
            </div>
            {/* You can add images for left and right here */}
            <img className="left" src={Kitchen2} alt="" />
            <img className="right" src={Kitchen3} alt="" />
          </div>
        </div>
        <div
          className={`cube-container ${isHovered ? "hovered" : ""}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <div className="photo-cube">
            <img className="front" src={Ceiling_1} alt="" />
            <div className="back photo-desc">
              <h3>Ceiling</h3>
              <p>
                Create a lasting impression with an elevated ceiling design.
              </p>
            </div>
            {/* You can add images for left and right here */}
            <img className="left" src={Ceiling_2} alt="" />
            <img className="right" src={Ceiling_3} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
