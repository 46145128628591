import React, { useState } from "react";
import { Container} from "react-bootstrap";
import WardrobeHeight from "../calculateWardrobe/WardrobeHeight";
import LoginForm from "./../../shared/Login/LoginForm";
import WardrobeType from "./WardrobeType";
import WardrobeFinish from "./WardrobeFinish";
import Stepper from "react-stepper-horizontal";
import "../CalculateForm.css";

function Wardrobe(props) {
  const [step, setStep] = useState(1);
  const [selectedHeight, setSelectedHeight] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedFinish, setSelectedFinish] = useState("");
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setStep(step + 1);
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setStep(step - 1);
    setActiveStep(activeStep - 1);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 1:
        return (
          <WardrobeHeight
            setSelectedHeight={setSelectedHeight}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );

      case 2:
        return (
          <WardrobeType
            setSelectedType={setSelectedType}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );

      case 3:
        return (
          <WardrobeFinish
            setSelectedFinish={setSelectedFinish}
            handleNext={handleNext}
            handleBack={handleBack}
          />
        );

      default:
        return <LoginForm />;
    }
  };

  return (
    <div style={{ marginTop: "10vh", marginBottom: "20vh" }}>
      <Container className="d-flex justify-content-center">
        <div
          style={{
            boxShadow: " 0 0.5rem 1rem rgba(0, 0, 0, 0.15)",
            padding: "2rem",
          }}
          className={step === 4 ? "background-image-calculate" : ""}
        >
          <Stepper
            steps={[
              { title: "Step 1" },
              { title: "Step 2" },
              { title: "Step 3" },
              { title: "Step 4" },
            ]}
            activeStep={activeStep}
          />
          {renderStepContent(step)}
        </div>
      </Container>
    </div>
  );
}

export default Wardrobe;
