import React, { useState } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import LoginForm from "../../shared/Login/LoginForm";
import ModalForm from "../../shared/Modal/ModalForm";
import "./offers.css";

function Offers() {
  const [showLoginForm, setShowLoginForm] = useState(false);

  function handleClick() {
    setShowLoginForm(true);
  }

  function handleClose() {
    setShowLoginForm(false);
  }
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <div>
      <ModalForm
        show={showLoginForm}
        onClose={handleClose}
        title="Get Your Design"
      >
        <LoginForm />
      </ModalForm>
      <Container fluid className="bg-light">
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            paddingTop: "2rem",
          }}
        >
          <div>
            <h1>What we do here!</h1>
          </div>
          <div>
            <Button
              size="lg"
              className="rounded-pill px-3"
              style={{
                backgroundColor: "rgba(92, 92, 221, 1)",
                color: "white",
                marginTop: "0rem",
              }}
              onClick={handleClick}
            >
              BOOK FREE CONSULTATION
            </Button>
          </div>
        </div>

        <Row className="mt-3">
          <Col xs={1} className="px-3"></Col>
          <Col xs={11} className="mt-3 mb-4 ">
            <Carousel
              responsive={responsive}
              arrows={false} // Hide the navigation arrows
              swipeable={true} // Allow touch-based swiping
            >
              {/* <Card style={{  display: "flex" , height: "24rem" }}> */}
              <Card style={{ display: "flex" }}>
                <Card.Body>
                  <Card.Title className="fw-bold">Our Services</Card.Title>

                  <ListGroup variant="flush">
                    <ListGroup.Item>Modular Kitchens</ListGroup.Item>
                    <ListGroup.Item>Modular Wardrobes</ListGroup.Item>
                    <ListGroup.Item>Flooring</ListGroup.Item>
                    <ListGroup.Item>Electrical Work</ListGroup.Item>
                    <ListGroup.Item>Civil Work</ListGroup.Item>
                    <ListGroup.Item>False Ceiling</ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
              <Card style={{ display: "flex" }}>
                <Card.Body>
                  <Card.Title className="fw-bold">Our Services</Card.Title>

                  <ListGroup variant="flush">
                    <ListGroup.Item>Modular Kitchens</ListGroup.Item>
                    <ListGroup.Item>Modular Wardrobes</ListGroup.Item>
                    <ListGroup.Item>Flooring</ListGroup.Item>
                    <ListGroup.Item>Electrical Work</ListGroup.Item>
                    <ListGroup.Item>Civil Work</ListGroup.Item>
                    <ListGroup.Item>False Ceiling</ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
              <Card style={{ display: "flex" }}>
                <Card.Body>
                  <Card.Title className="fw-bold">Our Services</Card.Title>

                  <ListGroup variant="flush">
                    <ListGroup.Item>Modular Kitchens</ListGroup.Item>
                    <ListGroup.Item>Modular Wardrobes</ListGroup.Item>
                    <ListGroup.Item>Flooring</ListGroup.Item>
                    <ListGroup.Item>Electrical Work</ListGroup.Item>
                    <ListGroup.Item>Civil Work</ListGroup.Item>
                    <ListGroup.Item>False Ceiling</ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
              <Card style={{ display: "flex" }}>
                <Card.Body>
                  <Card.Title className="fw-bold">Our Services</Card.Title>

                  <ListGroup variant="flush">
                    <ListGroup.Item>Modular Kitchens</ListGroup.Item>
                    <ListGroup.Item>Modular Wardrobes</ListGroup.Item>
                    <ListGroup.Item>Flooring</ListGroup.Item>
                    <ListGroup.Item>Electrical Work</ListGroup.Item>
                    <ListGroup.Item>Civil Work</ListGroup.Item>
                    <ListGroup.Item>False Ceiling</ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
              <Card style={{ display: "flex" }}>
                <Card.Body>
                  <Card.Title className="fw-bold">Our Services</Card.Title>

                  <ListGroup variant="flush">
                    <ListGroup.Item>Modular Kitchens</ListGroup.Item>
                    <ListGroup.Item>Modular Wardrobes</ListGroup.Item>
                    <ListGroup.Item>Flooring</ListGroup.Item>
                    <ListGroup.Item>Electrical Work</ListGroup.Item>
                    <ListGroup.Item>Civil Work</ListGroup.Item>
                    <ListGroup.Item>False Ceiling</ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </Card>
            </Carousel>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Offers;
