import './footer.css';
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { IoHome } from "react-icons/io5";
import { HiLightBulb } from "react-icons/hi";
import { MdCalculate } from "react-icons/md";
import { IoMdContact } from "react-icons/io";

function Footer() {
  const navigate = useNavigate();
  const [activeIcon, setActiveIcon] = useState("home");
  const [isMobileDevice, setIsMobileDevice] = useState(false);

  const handleClick = (iconName) => {
    setActiveIcon(iconName === activeIcon ? null : iconName);
    if (iconName === "home" || iconName === "letsBegin") {
      navigate("/");
    } else if (iconName === "designIdeas") {
      navigate("/designs");
    } else if (iconName === "getEstimate") {
      navigate("/priceCalculator");
    } else {
      navigate("/contact");
    }
  };

  useEffect(() => {
    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(details);

    setIsMobileDevice(isMobileDevice);
  }, []);

  const currentYear = new Date().getFullYear();

  return (
    <div
      style={{
        position: "fixed",
        bottom: "0",
        width: "100vw",
        backgroundColor: "white",
        borderTop: "1px solid #ccc",
        zIndex: "1",
      }}
    >
      {isMobileDevice ? (
        <div className="footer-container">
          <div className="footer-item">
            <IoHome className={`icon ${activeIcon === "home" ? "active" : ""
              }`} onClick={() => handleClick("home")} />

            <div className="icon-label">Home</div>
          </div>
          <div className="footer-item">
            <HiLightBulb className={`icon ${activeIcon === "designIdeas" ? "active" : ""
              }`} onClick={() => handleClick("designIdeas")} />

            <div className="icon-label">Design</div>
          </div>
          <div className="footer-item">
            <MdCalculate className={`icon ${activeIcon === "getEstimate" ? "active" : ""
              }`} onClick={() => handleClick("getEstimate")} />

            <div className="icon-label">Estimator</div>
          </div>
          <div className="footer-item">
            <IoMdContact className={`icon ${activeIcon === "more" ? "active" : ""
              }`} onClick={() => handleClick("more")} />
            <div className="icon-label">Contact</div>
          </div>
        </div>
      ) : (
        <div> @{currentYear} livAmaze</div>
      )}
    </div>
  );
}

export default Footer;
