import "./App.css";
import Header from "./Components/shared/header/header";
import Route from "./Components/route/route";

function App() {
  return (
    <div className="App">
      <Header />
      <Route />
    </div>
  );
}

export default App;
