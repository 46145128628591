import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "../home/home";
import CalculateForm from "../calculateForm/calculateRoom/CalculateForm";
import CalculateKitchen from "../calculateForm/calculateKitchen/CalculateKitchen";
import CalculateWardrobe from "../calculateForm/calculateWardrobe/Wardrobe";
import Design from "../home/designs/Design";
import Footer from "../shared/footer/Footer";
import PriceCalculator from "../calculateForm/priceCalculator/PriceCalculator";
import ContactUs from "../shared/contactUs/ContactUs";

function route() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/designs" element={<Design />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/priceCalculator" element={<PriceCalculator />} />
        <Route path="/calculateRoom" element={<CalculateForm />} />
        <Route path="/calculateKitchen" element={<CalculateKitchen />} />
        <Route path="/calculateWardrobe" element={<CalculateWardrobe />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default route;
